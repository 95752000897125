// html,
// body,
// .main,
// .jumb {
//   min-height: 100%;
// }

body {
  font-family: $main-font;
  background-color: $black;
  color: #fff;
  font-weight: 300;
}

.container {
  @include container();
  @include md(max-width, rem(750));
  @include lg(max-width, rem(930));
  width: 100%;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul {
  li + li {
    margin-top: 30px;
  }
}

img {
  max-width: 100%;
  max-height: 100%;
}

textarea {
  resize: none;
}

.text {
  &-left {
    text-align: left;
  }
  &-center {
    text-align: center;
  }
  &-right {
    text-align: right;
  }
  &-nowrap {
    white-space: nowrap;
  }
}

.upper {
  text-transform: uppercase;
}

.trans {
  transition: $trans;
}

.row-flex {
  @include row-flex();
}

.col {
  @include col();
}

.flex {
  display: flex;
  &-wrap {
    flex-wrap: wrap;
  }
  &-nowrap {
    flex-wrap: nowrap;
  }
  &-inline {
    display: inline-flex;
  }
  &-column {
    flex-flow: column;
  }
}

.j {
  &-center {
    justify-content: center;
  }
  &-between {
    justify-content: space-between;
  }
  &-end {
    justify-content: flex-end;
  }
}

.a {
  &-start {
    align-items: flex-start;
  }
  &-center {
    align-items: center;
  }
  &-end {
    align-items: flex-end;
  }
}

.w-100 {
  width: 100%;
}

.btn {
  position: relative;
  // overflow: hidden;
  outline: none;
  background: none;
  border: none;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  transition: $trans;
  line-height: 1;
  font-size: 18px;
  padding: 19px 17px;
  font-weight: 400;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    width: 25px;
  }
  &:before {
    background: url('../img/svg/btn-trans-left.svg') right no-repeat;
    background-size: auto 100%;
    left: 0;
    transform: translateX(-97%);
  }
  &:after {
    right: 0;
    background: url('../img/svg/btn-trans-right.svg') left no-repeat;
    background-size: auto 100%;
    transform: translateX(99%);
  }
  &.disabled {
    filter: blackscale(100%);
    pointer-events: none;
  }
  &_blue {
    background-color: $blue;
    color: #fff;
    border-top: 1px solid $blue;
    border-bottom: 1px solid $blue;
    &:before {
      background-image: url('../img/svg/btn-blue-left.svg');
      transform: translateX(-95%);
    }
    &:after {
      background-image: url('../img/svg/btn-blue-right.svg');
      transform: translateX(95%);
    }
    &:hover {
      box-shadow: 0 0 12px 2px rgba($blue, 0.5);
      transform: scale(1.05);
      color: #fff;
    }
  }
  &_trans {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    color: #fff;
    &:hover {
      transform: scale(1.05);
      color: #fff;
    }
    &-blue {
      border-color: $blue;
      color: $blue;
      &:hover {
        color: $blue;
      }
      &:before,
      &:after {
        filter: invert(34%) sepia(84%) saturate(6729%) hue-rotate(227deg)
          brightness(99%) contrast(86%);
      }
    }
    &-black {
      border-color: $dark;
      color: $dark;
      &:hover {
        color: $dark;
      }
      &:before,
      &:after {
        filter: invert(100%) sepia(4%) saturate(1036%) hue-rotate(169deg)
          brightness(96%) contrast(93%);
      }
    }
  }
  &_nav {
    padding: 16px 3px;
    border-color: rgba(#fff, 0.4);
    &-wrap {
      display: flex;
      align-items: center;
    }
    @include sm-block {
      padding-top: 13px;
      padding-bottom: 13px;
    }
    &:before,
    &:after {
      opacity: 0.4;
      transition: 0.5s;
    }
    &:hover {
      border-color: rgba(#fff, 1);
      &:before,
      &:after {
        opacity: 1;
      }
    }
    &-black {
      border-color: #9f9f9f;
      color: $dark;
      img {
        filter: invert(100%) sepia(96%) saturate(0%) hue-rotate(47deg)
          brightness(108%) contrast(104%);
      }
      &:hover {
        color: $dark;
        border-color: #000;
      }
      &:before,
      &:after {
        filter: invert(100%) sepia(96%) saturate(0%) hue-rotate(47deg)
          brightness(108%) contrast(104%);
      }
    }
    &-next {
      position: relative;
      margin-left: 40px;
      top: 18px;
      @include sm-block {
        margin-left: 34px;
      }
    }
  }
}

.breadcrumbs {
  padding-bottom: 30px;
  padding-top: 50px;
  display: flex;
  @include md-block {
    padding-top: 20px;
    overflow-x: auto;
    white-space: nowrap;
    margin-right: -15px;
    margin-left: 0;
  }
  @include sm-block {
    padding-bottom: 25px;
  }
  &__link {
    font-size: 14px;
    line-height: 17px;
    & + & {
      position: relative;
      padding-left: 24px;
      margin-left: 20px;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 4px;
        height: 4px;
        background-color: $blue;
      }
    }
    &:last-child {
      color: rgba(#fff, 0.4);
      pointer-events: none;
    }
  }
}

.dropdown {
  position: relative;
  ::-webkit-scrollbar {
    width: 1px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: $dark;
    // border: 1px solid $gray;
    // border-left: 1px solid #30546e;
    // padding: 5px 0;
    margin: 1px 0;
  }

  ::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 2px;
    height: 130px;
  }
  &.active {
    &:after {
      transform: rotate(-180deg) translateY(50%);
    }
    .input {
      border-color: $blue;
    }
  }
  .input {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 50px;
  }
  &__list {
    color: $dark;
    display: none;
    position: absolute;
    z-index: 50;
    top: 100%;
    margin-top: 0px;
    left: 0;
    right: 0;
    background-color: $dark;
    color: #fff;
    padding: 0 15px;
    max-height: 180px;
    overflow-y: auto;
  }
  &__item {
    padding: 15px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.5s;
    &.active {
      color: rgba(#fff, 0.3);
    }
    & + & {
      border-top: 1px solid #2a2a2b;
    }
    &:hover {
      color: $blue;
    }
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    transform-origin: center;
    width: 15px;
    height: 8px;
    transition: 0.5s;
    margin-top: -2px;
    background: url('../img/svg/icon-angel-down.svg') center no-repeat;
  }
}

.label {
  display: block;
  cursor: pointer;
  font-size: 14px;
  line-height: 17px;
  color: rgba(#fff, 0.4);
  transition: $trans;
  &_black {
    color: $gray;
  }
  .error & {
    color: #cd2c2c;
  }
}

.input {
  position: relative;
  border: none;
  background: none;
  outline: none;
  padding: 8px 0 10px;
  transition: 0.5s;
  color: $font-color;
  line-height: 1;
  cursor: pointer;
  border-bottom: 2px solid #fff;
  color: #fff;
  font-size: 16px;
  line-height: 1.5;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  &_black {
    border-color: $dark;
    color: $dark;
  }
  &:focus {
    caret-color: $blue;
  }
  &:focus,
  &:hover {
    border-color: $blue;
  }
  &_submit {
    position: relative;
    .input {
      padding-right: 40px;
    }
    &-btn {
      background: none;
      border: none;
      outline: none;
      transition: 0.5s;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      &:hover {
        transform: scale(1.1) translateY(-50%);
      }
    }
  }
  .error & {
    border-color: #cd2c2c;
  }
}

// TODO
.checkbox {
  display: block;
  position: relative;
  padding-left: 27px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      & ~ .checkbox__mark {
        background-color: $blue;
        &:after {
          display: block;
        }
      }
    }
  }

  &__mark {
    position: absolute;
    top: -3px;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 2px;
    border: 1px solid $blue;
    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 5px;
      top: 1px;
      width: 4px;
      height: 9px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

// TODO
.radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      & ~ .radio__mark {
        &:after {
          display: block;
        }
      }
    }
  }
  &__mark {
    position: relative;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px solid #000;
    border-radius: 50%;
    flex-shrink: 0;
    &:after {
      content: '';
      position: absolute;
      display: none;
      top: 3px;
      left: 3px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #000;
    }
  }
}

// TODO
.select {
  position: relative;
  select {
    display: none;
    & + label {
      top: 9px;
      font-size: 11px;
    }
  }
  &__selected {
    font-weight: 700;
    font-size: 13px;
    padding: 10px 30px 10px 10px;
    background: #ffffff;
    border: 1px solid #c2c1c5;
    border-radius: 2px;
    line-height: 1;
    transition: 0.5s;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .select_out & {
      border: none;
      padding: 25px 10px 6px;
      background: none;
      line-height: inherit;
      border: 1px solid #c2c1c5;
    }
    // &:hover {
    // border-color: $black;
    // }
    &:after {
      position: absolute;
      content: '';
      top: 28%;
      right: 10px;
      width: 6px;
      transition: 0.5s;
      height: 6px;
      transform: rotate(45deg);
      border: 2px solid transparent;
      border-color: transparent $gray $gray transparent;
    }
    &.active {
      &:after {
        top: 45%;
        border-color: $gray transparent transparent $gray;
      }
    }
  }

  &__items {
    position: absolute;
    background-color: $gray;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    div {
      font-weight: 700;
      font-size: 13px;
      padding: 10px 30px 10px 10px;
      background: #ffffff;
      border: 1px solid #c2c1c5;
      border-radius: 2px;
      line-height: 1;
      transition: 0.5s;
      cursor: pointer;
      &:hover,
      &.same-as-selected {
        background-color: #ccc;
      }
    }
  }

  &_hide {
    display: none;
  }
}

::placeholder {
  color: #a8a8a8;
}

.hidden {
  &-lg {
    @include lg-block {
      display: none;
    }
  }
  &-md {
    @include md-block {
      display: none;
    }
  }
  &-sm {
    @include sm-block {
      display: none;
    }
  }
}

.slick {
  &-slide,
  &-slide a {
    outline: none;
  }
  &-dots {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    li {
      width: 8px;
      height: 8px;
      background: rgba($gray, 0.4);
      border-radius: 50%;
      cursor: pointer;
      transition: $trans;
      margin: 0 10px;
      @include md-block {
        margin: 0 3px;
      }
      &.slick-active {
        background-color: $dark;
        pointer-events: none;
        transform: scale(1.1);
      }
      &:hover {
        background-color: $blue;
        border-color: $blue;
      }
    }
    button {
      display: none;
      background: none;
      border: none;
      font-size: 0;
    }
  }
}

.title {
  line-height: 1.2;
  font-weight: 400;
  &-h1 {
    font-size: 60px;
    font-weight: 500;
    @include sm-block {
      font-size: 40px;
    }
  }
  &-h2 {
    font-size: 42px;
    @include sm-block {
      font-size: 28px;
    }
  }
  &-h3 {
    font-size: 32px;
    @include sm-block {
      font-size: 22px;
    }
  }
  &-h4 {
    font-size: 24px;
    @include sm-block {
      font-size: 20px;
    }
  }
  &-h5 {
    font-size: 20px;
    @include sm-block {
      font-size: 19px;
    }
  }
  &-h6 {
    font-size: 16px;
    @include sm-block {
      font-size: 18px;
    }
  }
}

a {
  color: #fff;
  text-decoration: none;
  transition: $trans;
  &:hover {
    color: $gray;
  }
}

.link {
  font-size: 18px;
  line-height: 1.4;
  display: inline-block;
  &:hover {
    text-decoration: none;
  }
  &-small {
    font-weight: 500;
    font-size: 14px;
    color: $gray;
  }
  &-anim {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 30px;
      height: 2px;
      background-color: #fff;
      line-height: 1;
      margin-left: 12px;
      transition: width 1.5s;
    }

    &:hover {
      color: #fff;
      &:after {
        animation: button-icon-stretch-right 1.3s
          cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
      }
    }
  }
}

// @keyframes button-icon-stretch-right {
//   0% {
//     margin-left: 7px;
//     width: 30px;
//   }
//   1% {
//     width: 0px;
//   }
//   20% {
//     width: 8px;
//   }
//   80% {
//     margin-left: 35px;
//     width: 8px;
//   }
//   100% {
//     width: 0px;
//     margin-left: 42px;
//   }
// }

@keyframes button-icon-stretch-right {
  0% {
    margin-left: 12px;
    width: 0px;
  }
  45% {
    width: 30px;
    margin-left: 12px;
  }
  90% {
    width: 0px;
    margin-left: 42px;
  }
  100% {
    width: 0px;
    margin-left: 42px;
  }
}

.text {
  font-size: 16px;
  line-height: 1.6;
}

// Sections
.jumb {
  background: url('../img/jumb-bg.jpg') center bottom no-repeat;
  background-size: cover;
  padding: 80px 0;
  background-color: #070708;
  min-height: 100vh;
  display: flex;
  align-items: flex-end;
  margin-bottom: 50px;
  margin-top: -75px;
  @include md-block {
    margin-bottom: 0;
    background-size: auto 90%;
    background-color: #000;
    // background-size: auto 50%;
  }
  &__container {
    max-width: 820px;
  }
  &__title {
    max-width: 810px;
    font-weight: 400;
  }
  &-cat {
    margin-top: -75px;
    padding-top: 75px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 300px;
    box-shadow: 0px 44px 100px #000000, inset 2px 0px 0px #2c56ec;
    @include md-block {
      padding-bottom: 215px;
      box-shadow: 0px 44px 100px #000000;
    }
    @include sm-block {
      padding-bottom: 280px;
      min-height: 100vh;
    }
  }
  &-subcat {
    padding-bottom: 70px;
    background: url('../img/jumb-subcat-bg.png') top no-repeat;
    margin-bottom: -50px;
    @include sm-block {
      padding-bottom: 60px;
      margin-bottom: -25px;
      background-position: 40% -30px;
    }
  }
  &-product {
    background: url('../img/jumb-product-bg.png') top no-repeat;
    background-size: 100% auto;
    padding-bottom: 80px;
    @include md-block {
      background-position: center 15%;
    }
    @include sm-block {
      padding-bottom: 60px;
      background-size: 200% auto;
    }
    &__breadcrumbs {
      @include sm-block {
        justify-content: flex-start;
      }
    }
    &__title {
      font-weight: 400;
      margin: 0 auto 30px;
      @include sm-block {
        text-align: left;
        margin-bottom: 50px;
      }
    }
    &__info {
      margin-top: 170px;
      @include lg-block {
        display: block;
      }
      @include sm-block {
        margin-top: 60px;
      }
    }
    &__name {
      max-width: 300px;
      width: 100%;
      @include lg-block {
        max-width: 100%;
      }
    }
    &__descr {
      max-width: 500px;
      width: 100%;
      @include lg-block {
        max-width: 100%;
        margin-top: 35px;
      }
    }
    &__params {
      max-width: 200px;
      width: 100%;
      @include lg-block {
        display: flex;
        justify-content: center;
        max-width: inherit;
        margin-top: 55px;
        margin-left: -7px;
        margin-right: -7px;
        width: auto;
      }
    }
    &__param {
      @include lg-block {
        width: 190px;
        margin-left: 7px;
        margin-right: 7px;
      }
      & + & {
        margin-top: 30px;
        @include lg-block {
          margin-top: 0;
        }
      }
      &-label {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateY(-50%);
        background: $blue;
        padding: 3px 5px;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.02em;
        border-radius: 10px;
        white-space: nowrap;
        border: 1px solid $dark;
      }
      &-icon {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #fff;
        margin: 0 auto 10px;
      }
    }
  }
}

.cats {
  padding-top: 100px;
  @include sm-block {
    padding-top: 60px;
  }
  &__list {
    margin: 0 -5px;
    padding-bottom: 60px;
    @include sm-block {
      padding-bottom: 35px;
    }
  }
  &__item {
    background: #161719 url('../img/cats/cat-bg.png') right 350% no-repeat;
    transition: 0.5s;
    margin: 5px;
    flex: 1 1 30%;
    padding: 15px 65px 40px;
    min-height: 300px;
    @include lg-block {
      background: #161719;
      flex-basis: 45%;
      padding-left: 45px;
      padding-right: 45px;
      padding-bottom: 25px;
    }
    @include sm-block {
      flex-basis: 100%;
      display: flex;
      align-items: center;
      padding-left: 15px;
      padding-right: 15px;
      // padding-bottom: 15px;
      min-height: 170px;
    }
    &:hover {
      background-position: bottom right;
      position: relative;
      z-index: 2;
      color: #fff;
      box-shadow: 0 40px 60px 20px #000000, inset 2px 0px 0px $blue;
    }
    &_big {
      flex-basis: 45%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 65px;
      @include lg-block {
        flex-basis: 100%;
        & + & {
          flex-basis: 45%;
          flex-flow: column-reverse;
          padding: 15px 45px 40px;
          align-items: flex-start;
          .cats__image_big {
            height: 190px;
            margin-bottom: 0;
            width: 100%;
            align-items: flex-start;
            img {
              max-height: 90%;
            }
          }
        }
      }
      @include sm-block {
        flex-flow: row-reverse;
        justify-content: flex-end;
        padding-top: 20px;
        padding-bottom: 0;
        & + & {
          flex-flow: row-reverse;
          flex-basis: 100%;
          padding: 15px 15px 25px;
          align-items: center;
          .cats__image_big {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 120px;
            width: 120px;
            margin-right: 20px;
            text-align: center;
            flex-shrink: 0;
            @include to(rem(365)) {
              width: 90px;
              height: 90;
            }
          }
        }
      }
    }
  }
  &__image {
    height: 190px;
    line-height: 0;
    @include sm-block {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 120px;
      width: 120px;
      margin-right: 20px;
      text-align: center;
      flex-shrink: 0;
    }
    @include to(rem(365)) {
      width: 90px;
      height: 90;
    }
    &_big {
      line-height: 0;
      height: 280px;
      margin-bottom: -22px;
      @include sm-block {
        height: 120px;
        width: 120px;
        margin-right: 15px;
        flex-shrink: 0;
      }
      @include to(rem(365)) {
        width: 90px;
        height: 90;
      }
    }
  }
  &__name {
    max-width: 260px;
    @include to(rem(365)) {
      font-size: 16px;
    }
  }
  &__btn {
    @include sm-block {
      width: 82%;
    }
  }
}

.solutions {
  position: relative;
  background: url('../img/solutions-bg.png') left bottom no-repeat;
  background-size: contain;
  padding: 185px 0 150px;
  overflow: hidden;
  @include lg-block {
    padding-top: 150px;
    background-size: cover;
  }
  @include md-block {
    background-position: top;
  }
  @include sm-block {
    padding-top: 70px;
    padding-bottom: 75px;
  }
  &__row {
    @include md-block {
      display: block;
    }
  }
  &__nav {
    margin-top: 70px;
    @include md-block {
      padding-left: 20px;
      margin-top: 60px;
    }
    @include sm-block {
      margin-top: 30px;
    }
  }
  &__info {
    flex-shrink: 0;
    max-width: 470px;
    width: 100%;
    margin-right: 150px;
    @include md-block {
      min-width: 100%;
      padding: 0 15px;
    }
  }
  &__descr {
    margin-bottom: 60px;
    @include sm-block {
      margin-bottom: 35px;
    }
  }
  &__btn {
    min-width: 200px;
    text-align: left;
    @include md-block {
      text-align: center;
    }
    @include sm-block {
      width: 86%;
      &-wrap {
        text-align: center;
      }
    }
  }
  &__slider {
    flex-grow: 1;
    min-width: 0;
    margin-top: -35px;
    margin-right: -500px;
    @include md-block {
      margin-top: 70px;
    }
    @include sm-block {
      margin-top: 50px;
    }
  }
  &__slide {
    position: relative;
    transition: transform 0.5s;
    transition-delay: 0.4s;
    padding: 35px 20px;
    @include sm-block {
      padding: 25px 15px;
      transition-delay: 0.7s;
    }
    .slick-current & {
      transform: scale(1.15);
    }
    .slick-slide:not(.slick-current) & {
      transform: scale(1);
      transition-delay: 0s;
    }
    &-title {
      position: absolute;
      left: 50px;
      right: 80px;
      bottom: 75px;
      @include sm-block {
        left: 40px;
        right: 40px;
        bottom: 50px;
        font-size: 20px;
      }
    }
    &-image {
      width: 335px;
      object-fit: cover;
      @include sm-block {
        width: 165px;
      }
    }
  }
}

.about {
  position: relative;
  margin-bottom: 75px;
  @include sm-block {
    margin-bottom: 40px;
  }
  &__title {
    margin-bottom: 30px;
  }
  &__text {
    margin-bottom: 70px;
    @include sm-block {
      margin-bottom: 35px;
    }
  }
  &__row {
    @include md-block {
      flex-flow: column-reverse;
    }
  }
  &__image {
    flex-grow: 1;
    @include md-block {
      height: 450px;
    }
    @include sm-block {
      height: 210px;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 50%;
      object-fit: cover;
      min-height: 100%;
      @include lg-block {
        width: 45%;
      }
      @include md-block {
        min-height: auto;
        top: initial;
        width: 100%;
        height: 450px;
      }
      @include sm-block {
        height: 210px;
      }
    }
  }
  &__info {
    width: 490px;
    margin-left: 110px;
    padding: 125px 0 115px;
    @include lg-block {
      width: 45%;
    }
    @include md-block {
      width: 100%;
      margin-left: 0;
      padding: 0 15px;
      margin-bottom: 110px;
    }
    @include sm-block {
      margin-bottom: 60px;
    }
  }
  &__btn {
    min-width: 200px;
    text-align: left;
    @include md-block {
      text-align: center;
    }
    @include sm-block {
      width: 86%;
      &-wrap {
        text-align: center;
      }
    }
  }
}

.projects {
  position: relative;
  &__caption {
    position: absolute;
    top: 55px;
    right: 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.2;
    transform: rotate(90deg);
    display: inline-block;
    transform-origin: bottom left;
    img {
      position: relative;
      top: -2px;
      margin-left: 10px;
    }
  }
  &__item {
    position: relative;
    max-width: 890px;
    padding: 75px 0;
    margin-left: 200px;
    @include lg-block {
      margin-left: 0;
    }
    @include sm-block {
      padding: 30px 0;
    }
  }
  &__image {
    @include lg-block {
      margin-left: 200px;
      .projects__item-big & {
        margin-left: 0;
      }
    }
    @include sm-block {
      margin-left: 80px;
      display: block;
    }
    img {
      max-width: initial;
      @include lg-block {
        max-width: 100%;
      }
    }
  }
  &__title {
    position: absolute;
    top: 165px;
    left: -200px;
    @include lg-block {
      left: 0;
    }
    @include md-block {
      top: 130px;
    }
    @include sm-block {
      top: 60px;
    }
    .projects__item-big & {
      left: 100px;
      @include lg-block {
        left: 0;
      }
    }
  }
  &__info {
    margin-top: -50px;
    @include lg-block {
      margin-top: 40px;
      display: block;
    }
    @include sm-block {
      margin-top: 25px;
    }
    & > * {
      flex: 1;
    }
  }
  &__descr {
    margin-right: 105px;
    @include lg-block {
      margin-right: 0;
      margin-bottom: 70px;
    }
    @include sm-block {
      margin-bottom: 35px;
    }
  }
  &__btn {
    min-width: 200px;
    @include sm-block {
      width: 86%;
    }
    &-wrap {
      padding-top: 75px;
      @include sm-block {
        padding-top: 10px;
      }
    }
  }
}

.discuss {
  padding: 220px 0 150px;
  margin-top: -70px;
  background: url('../img/discuss-bg.png') top no-repeat;
  @include md-block {
    background-size: contain;
  }
  @include sm-block {
    margin-top: 0;
    padding-top: 60px;
    padding-bottom: 80px;
  }
  &__contacts {
    &-block {
      @include size-sm(12);
      @include sm-block {
        text-align: center;
      }
      & + & {
        @include sm-block {
          margin-top: 36px;
        }
      }
    }
  }
  &-seo {
    padding-top: 150px;
    @include sm-block {
      padding-top: 60px;
    }
    &__title {
      color: rgba($gray, 0.8);
      margin-bottom: 30px;
      @include sm-block {
        margin-bottom: 15px;
      }
    }
    &__text {
      color: rgba($gray, 0.7);
    }
    &__link {
      color: rgba($gray, 0.7);
      border-bottom: 1px solid rgba($gray, 0.7);
      &:hover {
        color: #fff;
        border-color: #fff;
      }
    }
  }
  &__note {
    font-weight: 500;
    font-size: 120px;
    line-height: 1;
    color: rgba(#fff, 0.1);
    margin-bottom: -40px;
    background: -webkit-linear-gradient(#202021, #0e0e0f);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    @include sm-block {
      font-size: 55px;
      margin-bottom: -10px;
      background: -webkit-linear-gradient(#202021, #0f0f10);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;
    }
  }
}

.intro {
  // min-height: 100vh;
  &-slider {
    .slick-track {
      display: flex !important;
    }

    .slick-slide {
      height: inherit !important;
      & > div {
        display: flex;
        height: 100%;
      }
    }
    &__wrap {
      position: relative;
      margin-top: 70px;
      @include sm-block {
        margin-top: 40px;
      }
    }
    &__indicator {
      position: absolute;
      bottom: 0;
      left: 0;
      @include lg-block {
        left: 40px;
        bottom: 12px;
      }
      @include sm-block {
        left: 15px;
      }
      &-num {
        font-size: 24px;
        line-height: 1.2;
      }
      &-divider {
        width: 40px;
        height: 2px;
        background-color: #fff;
        margin: 0 12px;
      }
    }
    &__nav {
      position: absolute;
      right: 0;
      bottom: 0;
      background-color: $blue;
      height: 102px;
      padding: 12px 40px;
      @include sm-block {
      }
    }
    &__btn {
      min-width: 200px;
      @include lg-block {
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        color: #fff;
        &:before,
        &:after {
          filter: none;
        }
      }
      @include sm-block {
        margin-left: 20px;
        min-width: 180px;
      }
    }
    &__slide {
      display: flex !important;
      @include sm-block {
        padding-bottom: 130px;
      }
    }
    &__info {
      position: relative;
      z-index: 1;
      width: 380px;
      flex-shrink: 0;
      padding: 50px 40px;
      background-color: #fff;
      margin-bottom: 50px;
      @include lg-block {
        background: transparent;
      }
      @include sm-block {
        padding: 40px 15px 20px;
        width: 100%;
        margin-bottom: 0;
      }
    }
    &__title {
      color: #121314;
      margin-bottom: 10px;
      @include lg-block {
        color: #fff;
      }
    }
    &__descr {
      color: gray;
      margin-bottom: 50px;
      @include lg-block {
        color: #fff;
      }
    }
    &__image {
      margin-left: -60px;
      height: 100%;
      img {
        min-height: 100%;
        min-width: 100%;
        object-fit: cover;
      }
      @include lg-block {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin-left: 0;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: rgba(15, 16, 17, 0.5);
        }
      }
    }
  }
}

.consult {
  padding: 100px 0;
  background: $gray url('../img/solutions-bg.png') center no-repeat;
  @include sm-block {
    padding-left: 20px;
    padding-right: 20px;
  }
  &__inner {
    max-width: 590px;
    margin: auto;
  }
  &__input {
  }
  &__title {
    margin-bottom: 50px;
    @include sm-block {
      br {
        display: none;
      }
    }
  }
}

.products {
  margin-top: 50px;
  padding: 80px 0;
  background-color: #fff;
  @include sm-block {
    margin-top: 25px;
    padding-top: 60px;
  }
  &__head {
    margin-bottom: 50px;
    @include sm-block {
      display: block;
      margin-bottom: 25px;
    }
  }
  &__title {
    color: $dark;
  }
  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      '. . .'
      '. . .'
      '. . .'
      'consult consult consult';
    grid-template-rows: auto;
    gap: 10px 10px;
    @include md-block {
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        '. .'
        '. .'
        '. .'
        '. .'
        'consult consult';
    }
    @include sm-block {
      grid-template-columns: 1fr;
      grid-template-areas:
        '.'
        '.'
        '.'
        '.'
        '.'
        '.'
        '.'
        '.'
        'consult';
    }
  }
  .consult {
    grid-area: consult;
  }
  &__btn {
    min-width: 200px;
    &-wrap {
      margin-top: 80px;
      @include sm-block {
        margin-top: 35px;
      }
    }
  }
}

.product {
  background: $dark url('../img/cats/cat-bg.png') right 350% no-repeat;
  padding: 50px;
  @include sm-block {
    padding: 40px 25px 50px;
  }
  &:hover {
    background-position: bottom right;
    position: relative;
    z-index: 1;
    box-shadow: 0 25px 60px 20px rgba(#000000, 0.8), inset 2px 0px 0px #2c56ec;
  }
  &__image {
    margin-bottom: 50px;
    height: 290px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include sm-block {
      height: 250px;
    }
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
  &__title {
    margin-bottom: 20px;
  }
  &__descr {
    color: $gray;
  }
}

.filters {
  margin: 0 -25px 40px;
  @include md-block {
    display: block;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 60px;
  }
  &-reset {
    display: flex;
    align-items: center;
    color: $gray;
    @include sm-block {
      margin-top: 25px;
    }
    &:hover {
      color: $dark;
    }
    img {
      margin-left: 12px;
    }
  }
  &__group {
    padding: 0 25px;
    margin-bottom: 30px;
    @include md-block {
      padding: 0;
    }
  }
  &__half {
    width: 50%;
    @include md-block {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  }
  &__quarter {
    width: 25%;
    @include md-block {
      width: 100%;
    }
  }
}

.portfolio {
  padding-top: 150px;
  @include sm-block {
    padding-top: 60px;
  }
  &__head {
    position: relative;
    margin-bottom: 80px;
    flex-wrap: nowrap;
    @include sm-block {
      margin-bottom: 45px;
      flex-wrap: wrap;
    }
  }
  &__title {
  }
  &__nav {
    display: flex;
    flex-shrink: 0;
    @include md-block {
      padding-right: 15px;
    }
    @include sm-block {
      // position: absolute;
      // right: 10px;
      // top: 50px;
      @include size-sm(12);
      margin-top: 20px;
      justify-content: flex-end;
    }
  }
  &__item {
    position: relative;
    padding: 0 5px;
    text-align: center;
    img {
      margin: auto;
      @include sm-block {
        min-width: 100%;
        object-fit: cover;
      }
    }
  }
  &__caption {
    position: absolute;
    left: 50px;
    right: 50px;
    bottom: 50px;
    text-align: left;
    @include sm-block {
      left: 35px;
      right: 35px;
      bottom: 50px;
      font-size: 32px;
    }
  }
  &__btn {
    @include sm-block {
      width: 86%;
    }
    &-wrap {
      margin-top: 70px;
      @include sm-block {
        margin-top: 35px;
      }
    }
  }
}

.models {
  &__caption {
    margin-bottom: 15px;
  }
  &__item {
    padding: 30px 35px;
    background: $dark;
    color: $gray;
    transition: 0.5s;
    cursor: pointer;
    @include sm-block {
      width: 50%;
      text-align: center;
      padding: 30px 20px;
      &:nth-child(odd) {
        border-right: 1px solid rgba(106, 111, 115, 0.15);
      }
      &:nth-child(3),
      &:nth-child(4) {
        border-top: 1px solid rgba(106, 111, 115, 0.15);
      }
    }
    &:hover {
      background-color: darken($color: $dark, $amount: 2);
    }
    & + & {
      box-shadow: inset 1px 0px 0px rgba(106, 111, 115, 0.15);
      @include sm-block {
        box-shadow: none;
      }
    }
    &.active {
      color: $blue;
    }
  }
}

.config {
  background-color: #fff;
  color: $black;
  padding: 70px 0 100px;
  @include md-block {
    padding-bottom: 45px;
  }
  @include sm-block {
    padding-top: 65px;
  }
  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $dark;
    margin-right: 110px;
    max-width: 490px;
    width: 100%;
    @include lg-block {
      padding: 40px 20px;
      max-width: 100%;
      margin-bottom: 50px;
      margin-right: 15px;
    }
    @include sm-block {
      margin-bottom: 35px;
    }
  }
  &__title {
    max-width: 690px;
    margin: auto;
    padding-bottom: 50px;
    @include sm-block {
      padding-bottom: 35px;
    }
  }
  &__info {
    flex-grow: 1;
  }
  &__subtitle {
    margin-bottom: 30px;
  }
  &__param {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    border-top: 1px solid rgba($dark, 0.1);
    &:last-child {
      border-bottom: 1px solid rgba($dark, 0.1);
    }
    &-name {
      font-size: 14px;
      line-height: 17px;
      color: $gray;
      @include size-sm(12);
    }
    &-value {
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 22px;
      color: $gray;
      @include size-sm(12);
      @include sm-block {
        margin-top: 15px;
      }
    }
    &-option {
      color: $black;
      cursor: pointer;
      transition: 0.5s;
      img {
        position: relative;
        top: 1px;
      }
      & + & {
        margin-left: 15px;
      }
      &:hover {
        color: $gray;
      }
      &.active {
        color: $blue;
        img {
          filter: invert(27%) sepia(89%) saturate(2798%) hue-rotate(223deg)
            brightness(92%) contrast(101%);
        }
      }
    }
  }
  &__buttons {
    margin-top: 50px;
    @include lg-block {
      justify-content: flex-start;
    }
    @include sm-block {
      display: block;
      margin-top: 35px;
      text-align: center;
    }
  }
  &__btn {
    margin: 0 30px;
    min-width: 200px;
    @include sm-block {
      width: 86%;
      margin: 0;
      & + & {
        margin-top: 25px;
      }
    }
  }
}

.pdp-about {
  padding-top: 100px;
  @include lg-block {
    padding-top: 80px;
  }
  @include sm-block {
    padding-top: 60px;
  }
  &__title {
    margin-bottom: 20px;
    @include sm-block {
      margin-bottom: 30px;
    }
  }
  &__block {
    display: flex;
    justify-content: space-between;
    @include lg-block {
      flex-wrap: wrap;
    }
    & + & {
      padding-top: 100px;
      @include sm-block {
        padding-top: 60px;
      }
    }
  }
  &__col {
    &-first {
      width: 290px;
      max-width: 100%;
      @include lg-block {
        width: 100%;
        margin-bottom: 50px;
      }
      @include sm-block {
        margin-bottom: 35px;
      }
    }
    &-second {
      width: 790px;
      max-width: 100%;
      @include lg-block {
        width: 100%;
      }
    }
  }
  &__order {
    &-title {
      margin-bottom: 30px;
      @include sm-block {
        text-align: center;
        margin-bottom: 35px;
      }
    }
    &-btn {
      min-width: 200px;
      @include sm-block {
        width: 86%;
      }
      & + & {
        margin-left: 70px;
        @include sm-block {
          margin-left: 0;
          margin-top: 20px;
        }
      }
    }
    &-buttons {
      margin-left: 20px;
      @include sm-block {
        flex-wrap: wrap;
        justify-content: center;
        margin-left: 0;
      }
    }
  }
}

.pdp-spec {
  &__icons {
    display: flex;
    align-self: center;
    flex-wrap: wrap;
    margin: 0 -12px;
    @include sm-block {
      flex-wrap: nowrap;
    }
    img {
      padding: 0 12px 20px;
      @include sm-block {
        padding-bottom: 0;
        max-width: 20%;
      }
    }
    &-last {
      @include lg-block {
        order: 1;
      }
    }
  }
  &__images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 10px 10px;
    margin-bottom: 70px;
    @include sm-block {
      margin-bottom: 50px;
    }
  }
  &__main {
    display: flex;
    justify-content: space-between;
    @include md-block {
      display: block;
    }
    &-info {
      width: 490px;
      max-width: 100%;
      @include md-block {
        width: 100%;
      }
    }
    &-files {
      width: 190px;
      display: flex;
      flex-flow: column;
      max-width: 100%;
      @include md-block {
        flex-flow: row;
        width: 100%;
        margin-top: 50px;
      }
    }
    &-file {
      display: flex;
      flex-flow: column;
      align-items: center;
      @include md-block {
        width: 190px;
      }
      & + & {
        margin-top: 30px;
        @include md-block {
          margin-top: 0;
        }
      }
      img {
        transition: 0.5s;
        margin-bottom: 10px;
      }
      &:hover {
        color: #fff;
        img {
          transform: scale(1.1);
        }
      }
    }
    &-title {
      margin-bottom: 45px;
      @include sm-block {
        margin-bottom: 35px;
      }
    }
    &-list {
      margin-bottom: 30px;
      li {
        position: relative;
        padding-left: 14px;
        &:before {
          position: absolute;
          left: 0;
          top: 0;
          content: '~';
          color: $blue;
          font-size: 16px;
          line-height: 1.6;
          font-weight: 800;
        }
      }
    }
  }
}

.pdp-docs {
  &__col-first {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    @include lg-block {
      flex-flow: row;
    }
    @include sm-block {
      display: block;
    }
  }
  &__nav {
    margin-bottom: 20px;
    @include sm-block {
      justify-content: flex-end;
      margin-right: 15px;
      margin-top: -10px;
    }
  }
  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 10px;
    width: 130px;
    height: 185px;
  }
  &__slider {
    @include sm-block {
      margin-left: 0;
      margin-right: -15px;
    }
  }
  &__slide {
    padding: 30px 15px;
    border-left: 5px solid $black;
    border-right: 5px solid $black;
    background-color: $dark;
  }
}

.pdp-consult {
  margin-top: 100px;
  @include sm-block {
    margin-top: 60px;
  }
}

.similar {
  background: #fff;
  color: $dark;
  padding: 100px 0;
  margin-top: 150px;
  @include sm-block {
    margin-top: 60px;
    padding: 65px 0;
  }
  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
  }
  &__slider {
    @include sm-block {
      margin-right: -15px;
      margin-left: 0;
    }
  }
  &__product {
    @include md-block {
      max-width: 355px;
    }
    @include sm-block {
      max-width: 325px;
    }
  }
  &__head {
    margin-bottom: 70px;
    flex-wrap: nowrap;
    @include sm-block {
      margin-bottom: 35px;
    }
  }
  &__nav {
    display: flex;
    flex-shrink: 0;
    @include md-block {
      padding-right: 15px;
    }
  }
  &__item {
    position: relative;
    margin: 0 5px;
  }
  &__caption {
    position: absolute;
    left: 50px;
    right: 50px;
    bottom: 50px;
  }
  &__btn {
    @include sm-block {
      width: 86%;
    }
    &-wrap {
      margin-top: 70px;
      @include sm-block {
        text-align: center;
        margin-top: 55px;
      }
      @include sm-block {
        margin-top: 35px;
      }
    }
  }
  .slick-slide {
    padding: 0 5px;
    & > div,
    & > div > a {
      height: 100%;
    }
  }
}
