.header {
  position: relative;
  z-index: 10;
  padding: 24px 0;
  height: 75px;
  &__logo {
    position: relative;
    z-index: 1;
    line-height: 0;
    @include lg-block {
      .nav-active & {
        position: fixed;
        top: 26px;
      }
    }
    img {
      max-width: 154px;
      height: 25px;
    }
  }

  &-tools {
    display: flex;
    align-items: center;
    @include sm-block {
      position: fixed;
      justify-content: center;
      bottom: 20px;
      left: 0;
      right: 0;
      display: none;
      .nav-active & {
        display: flex;
      }
    }
    &__option {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 15px;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 25px;
        width: 1px;
        background: #fff;
      }
      &:last-child {
        padding-right: 0;
      }
      & > img {
        max-width: 20px;
        max-height: 20px;
      }
    }
  }
}

.lang {
  position: relative;
  &:after {
    display: none;
  }
  .input {
    border: 0;
    padding-right: 0;
  }
  &__list {
    padding: 0;
    @include sm-block {
      top: 0;
      transform: translateY(-100%);
    }
  }
  &__item {
    padding: 8px 10px;
  }
}

.basket {
  position: relative;
  z-index: 50;
  &__icon {
    position: relative;
    display: block;
    top: 2px;
  }
  &-count {
    position: absolute;
    top: -11px;
    left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50px;
    background-color: $blue;
    min-width: 18px;
    min-height: 18px;
    text-align: center;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.02em;
    padding: 1px 3px;
  }
  &-product {
    padding: 15px 10px;
    border-bottom: 1px solid rgba(#fff, 0.1);
    &__image {
      margin: -3px;
      width: 40px;
    }
    &__title {
      display: block;
      margin-bottom: 10px;
    }
    &__info {
      margin-left: 10px;
      margin-right: 20px;
    }
    &__close {
      &:hover {
        opacity: 0.5;
      }
      img {
        filter: invert(100%) sepia(0%) saturate(100%) hue-rotate(343deg)
          brightness(285%) contrast(99%);
      }
    }
  }
  &__dropdown {
    position: absolute;
    display: none;
    background: $dark;
    width: 390px;
    top: 100%;
    transform: translateY(20px);
    right: 0;
    box-shadow: 0px 44px 100px #000000;
    @include md-block {
      display: none !important;
    }
  }
  &__footer {
    padding: 30px 0;
  }
}

.count {
  display: inline-flex;
  background: $black;
  height: 45px;
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    padding: 0;
    width: 32px;
    line-height: 0;
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    transition: 0.5s;
    &:hover {
      background-color: $blue;
    }
  }

  &__value {
    text-align: center;
    border: none;
    background: transparent;
    width: 70px;
  }

  &__value::-webkit-inner-spin-button,
  &__value::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &__value[type='number'] {
    -moz-appearance: textfield;
  }
}

.nav {
  @include lg-block {
    position: fixed;
    padding-top: 75px;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background: black;
    display: none;
    .nav-active & {
      display: flex;
    }
  }
  @include sm-block {
    padding-bottom: 85px;
  }
  &__link {
    margin-right: 29px;
    @include lg-block {
      font-weight: 500;
      font-size: 3.5vw;
      margin-right: 0;
      & + & {
        margin-top: 7.8vh;
        @include sm-block {
          margin-top: 5.5vh;
        }
      }
    }
    @include sm-block {
      font-size: 28px;
      line-height: 1.2;
      padding: 0 12px;
    }
  }
}

.burger {
  position: relative;
  justify-content: center;
  align-items: center;
  height: 28px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  margin-left: 35px;
  display: none;
  @include lg-block {
    display: flex;
  }
  &:before {
    content: '';
    position: absolute;
    top: 15px;
    left: 11px;
    height: 5px;
    width: 4px;
    background-color: $black;
    z-index: 1;
    transform: rotate(-45deg);
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.7s;
    transition-delay: 0;
  }
  &:after {
    content: '';
    position: absolute;
    top: 15px;
    left: 16px;
    height: 5px;
    width: 8px;
    background-color: $black;
    z-index: 1;
    transform: rotate(45deg);
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.7s;
    transition-delay: 0;
  }
  &_open {
    position: fixed;
    right: 15px;
    top: 24px;
    &:before {
      opacity: 1;
      transition-delay: 0.3s;
    }
    &:after {
      opacity: 1;
      transition-delay: 0.3s;
    }
  }
  &__inner {
    width: 35px;
    height: 3px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
    transition: all 0.5s ease-in-out;
    // transform: translateX(0);
    .burger_open & {
      transform: translateX(-50px);
      background: transparent;
      box-shadow: none;
    }
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 35px;
      height: 3px;
      background: #fff;
      box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
      transition: all 0.5s ease-in-out;
      .burger_open & {
        height: 2px;
      }
    }
    &::before {
      transform: translateY(-10px);
      .burger_open & {
        transform: rotate(45deg) translate(35px, -35px);
      }
    }
    &::after {
      transform: translateY(10px);
      .burger_open & {
        transform: rotate(-45deg) translate(35px, 35px);
      }
    }
  }
}
