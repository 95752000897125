// Fonts
$main-font: 'ArticulatCF', sans-serif;
$font-color: #1b1726;

// Brand
$dark: #121314;
$black: #070708;
$blue: #2c56ec;
$gray: #6a6f73;

// Options
$trans: 0.5s;
