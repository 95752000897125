@font-face {
  font-family: 'ArticulatCF';
  src: url('../fonts/articulat/ArticulatCF-Light.eot');
  src: url('../fonts/articulat/ArticulatCF-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/articulat/ArticulatCF-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'ArticulatCF';
  src: url('../fonts/articulat/ArticulatCF-Regular.eot');
  src: url('../fonts/articulat/ArticulatCF-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/articulat/ArticulatCF-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ArticulatCF';
  src: url('../fonts/articulat/ArticulatCF-Medium.eot');
  src: url('../fonts/articulat/ArticulatCF-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/articulat/ArticulatCF-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'ArticulatCF';
  src: url('../fonts/articulat/ArticulatCF-DemiBold.eot');
  src: url('../fonts/articulat/ArticulatCF-DemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/articulat/ArticulatCF-DemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'ArticulatCF';
  src: url('../fonts/articulat/ArticulatCF-Bold.eot');
  src: url('../fonts/articulat/ArticulatCF-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/articulat/ArticulatCF-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ArticulatCF';
  src: url('../fonts/articulat/ArticulatCF-ExtraBold.eot');
  src: url('../fonts/articulat/ArticulatCF-ExtraBold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/articulat/ArticulatCF-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'ArticulatCF';
  src: url('../fonts/articulat/ArticulatCF-Heavy.eot');
  src: url('../fonts/articulat/ArticulatCF-Heavy.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/articulat/ArticulatCF-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

// @font-face {
//   font-family: "ArticulatCF";
//   src: url("../fonts/articulat/ArticulatCF-Bold.eot");
//   src: url("../fonts/articulat/ArticulatCF-Bold.eot?#iefix")
//       format("embedded-opentype"),
//     url("../fonts/articulat/ArticulatCF-Bold.woff") format("woff");
//   font-weight: bold;
//   font-style: normal;
// }

// @font-face {
//   font-family: "ArticulatCF";
//   src: url("../fonts/articulat/ArticulatCF-Bold.eot");
//   src: url("../fonts/articulat/ArticulatCF-Bold.eot?#iefix")
//       format("embedded-opentype"),
//     url("../fonts/articulat/ArticulatCF-Bold.woff") format("woff");
//   font-weight: bold;
//   font-style: normal;
// }
