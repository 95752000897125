.footer {
  background-color: $dark;
  font-size: 18px;
  line-height: 1.4;
  @include sm-block {
    text-align: center;
  }
  &-main {
    padding: 60px 0;
    flex-wrap: nowrap;
    @include lg-block {
      flex-wrap: wrap;
    }
    @include sm-block {
      padding: 40px 0 35px;
    }
    &__col {
      @include size-lg(4);
      @include size-sm(12);
    }
    li {
      & + li {
        margin-top: 25px;
        @include sm-block {
          margin-top: 20px;
        }
      }
    }
  }
  &__nav {
    line-height: 1.2;
    & + & {
      @include lg-block {
        padding-left: 30px;
      }
      @include sm-block {
        padding-left: 0;
        margin-top: 20px;
      }
    }
  }
  &__phones {
    font-weight: 700;
    @include lg-block {
      display: flex;
      @include size(8);
      margin-top: 65px;
    }
    @include sm-block {
      margin-top: 45px;
      margin-bottom: 35px;
    }
    &-item {
      @include lg-block {
        flex: 1;
      }
      & + & {
        @include lg-block {
          margin-top: 0;
          margin-left: 90px;
        }
        @include sm-block {
          margin-left: 0;
        }
      }
    }
    &-link {
      @include sm-block {
        font-size: 14px;
      }
    }
  }
  &__socials {
    text-align: right;
    @include lg-block {
      margin-top: 65px;
    }
    @include sm-block {
      text-align: center;
      margin-top: 20px;
      order: 2;
    }
    &-link {
      line-height: 0;
      & + & {
        margin-left: 24px;
      }
    }
  }
  &__address {
    line-height: 1.6;
    @include sm-block {
      order: 1;
    }
  }
  &-sub {
    padding: 60px 0 70px;
    color: rgba(#fff, 0.3);
    border-top: 1px solid rgba(#fff, 0.2);
    @include sm-block {
      padding: 40px 0;
      .col {
        @include size(12);
      }
    }
    &__link {
      color: rgba(#fff, 0.3);
      @include sm-block {
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }
  &__copyright {
    font-size: 18px;
    line-height: 1.4;
    @include sm-block {
      font-size: 14px;
    }
  }
}
